import React from 'react'

const products = () => {
    return (
        <div>
            <h1>This is Products Page</h1>
        </div>
    );
};

export default products