import React from 'react'

const blog = () => {
    return (
        <div>
            <h1>This is Blog Page</h1>
        </div>
    );
};

export default blog