import React from 'react'

const links = () => {
    return (
        <div>
            <h1>This is Links Page</h1>
        </div>
    );
};

export default links