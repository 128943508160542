import React from 'react'

const about = () => {
    return (
        <div>
            <h1>This is About Page</h1>
        </div>
    );
};

export default about